/* src/BYN.css */

.build-device-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #007D00;
  }
  
  .selectors {
    margin-bottom: 20px;
  }
  
  .selector {
    margin-bottom: 15px;
  }
  
  .selector label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-section {
    margin-bottom: 10px;
  }
  
  .accordion-section button {
    width: 100%;
    padding: 10px;
    background-color: #42AE4D;
    color: white;
    border: none;
    border-radius: 5px;
    text-align: left;
    font-size: 18px;
    cursor: pointer;
  }
  
  .accordion-section button:hover {
    background-color: #007D00;
  }
  
  .accordion-content {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .checkbox {
    margin-bottom: 10px;
  }
  
  .total {
    text-align: center;
    margin-top: 20px;
  }
  
  .total h2 {
    font-size: 24px;
    color: #333;
  }
  
  .checkout-button {
    background-color: #42AE4D;
    color: white;
    padding: 15px 25px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .checkout-button:hover {
    background-color: #007D00;
  }
  
  .flashing-text {
    font-size: 18px;
    color: #FF0000;
    margin-top: 10px;
    animation: flashing 1s infinite;
  }
  
  @keyframes flashing {
    0%, 50% {
      opacity: 1;
    }
    25%, 75% {
      opacity: 0.5;
    }
  }
  
  /* Modal styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
    color: #007D00;
  }
  
  .modal-content p {
    margin: 10px 0;
  }
  
  .modal-content button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #42AE4D;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #007D00;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .submit-button {
    background-color: #007D00;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #42AE4D;
  }

  .success {
    text-align: center;
  }
  
  .success h2 {
    color: seagreen;
  }
  
  .success a {
    display: block;
  }
  
  .checkoutForm {
    margin: auto 35px;
  }
  
  .checkoutForm .ua * {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 5% 20% 0 20%;
  }
  
  .checkoutForm .split {
    margin: 5% 20% 0 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .checkoutForm .split .b1 {
    max-width: 100px;
    background-color: #dd000b;
    margin: auto 10px;
  }
  
  .checkoutForm .split .b2 {
    max-width: 100px;
    margin: auto 10px;
  }
  
  .checkoutForm p {
    text-align: center;
  }
  
  .checkoutForm label {
    min-width: 250px;
    margin: 1px 10px;
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }
  
  .checkoutForm input,
  .checkoutForm .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  
  .checkoutForm input::placeholder,
  .checkoutForm .StripeElement::placeholder {
    color: #aab7c4;
  }
  
  .checkoutForm input:focus,
  .checkoutForm .StripeElement--focus,
  .checkoutForm .StripeElement:focus,
  .checkoutForm .StripeElement .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }
  
  .checkoutForm a {
    display: block;
    text-align: center;
    margin-top: 1em;
  }
  
  .orderButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
    margin-top: 5%;
    background-color: #008cdd;
    border: 1px solid #008cdd;
    font-size: 1em;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    padding: 0.5em 1em;
    width: 200px;
    transition: opacity 200ms;
    cursor: pointer;
  }
  
  .orderButton.success {
    background-color: seagreen;
    border-color: seagreen;
  }
  
  .orderButton:hover {
    opacity: 0.8;
  }
  
  .orderButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 25px;
  }
  
  .container .fb {
    height: auto;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7); /* Darken the background */
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #007D00;
    text-align: center;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content label {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .modal-content input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 20px;
    width: calc(100% - 22px);
  }
  
  .modal-content input:focus {
    border-color: #007D00;
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 125, 0, 0.5);
  }
  
  .split {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .split .b1, .split .b2 {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    border: none;
    width: 48%;
    color: white;
  }
  
  .split .b1 {
    background-color: #FF0000; /* Red for Cancel */
  }
  
  .split .b2 {
    background-color: #007D00; /* Green for Proceed */
  }
  
  .split .b1:hover {
    background-color: #cc0000;
  }
  
  .split .b2:hover {
    background-color: #005900;
  }
  
  @media (max-width: 768px) {
    .modal-content {
      padding: 15px;
    }
  
    .modal-content h2 {
      font-size: 20px;
    }
  
    .split .b1, .split .b2 {
      font-size: 14px;
      padding: 8px;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  
  