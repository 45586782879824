/* src/KYC.css */

.kyc-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #007D00;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  select,
  input[type="file"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 18px;
    color: #ffffff;
    background-color: #888888; /* Gray to indicate "Coming Soon" */
    border: none;
    border-radius: 5px;
    cursor: not-allowed;
    text-align: center;
    margin-top: 20px;
  }
  