/* Main branding container */
.branding-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  /* Branding section */
  .branding {
    text-align: center;
  }
  
  .branding-header {
    font-size: 4rem;
    font-weight: bold;
    color: #333;
  }
  
  .branding-text {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 2em;
  }
  
  /* Navigation buttons */
  .navigation-buttons {
    display: flex;
    justify-content: center;
    gap: 1em;
  }
  
  .nav-button {
    padding: 1em 2em;
    font-size: 1.2rem;
    color: white;
    background-color: #2185d0;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .nav-button:hover {
    background-color: #1678c2;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .branding-header {
      font-size: 3rem;
    }
  
    .branding-text {
      font-size: 1.2rem;
    }
  
    .nav-button {
      width: 100%;
      padding: 1em;
    }
  
    .navigation-buttons {
      flex-direction: column;
    }
  }
  