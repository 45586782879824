/* src/App.css */
.app-container {
  font-family: Arial, sans-serif;
}

.app-header {
  background-color: #42AE4Dff;
  padding: 10px;
  text-align: center;
}

.nav-link {
  margin: 0 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #AAD400ff;
}

main {
  padding: 20px;
}

/* src/App.css */
.nav-link {
  margin-right: 20px;
  text-decoration: none;
  color: #007D00;
  position: relative;
  font-weight: bold;
}

.disabled-link {
  color: #007D00;
  cursor: not-allowed;
  position: relative;
}

.disabled-link::after {
  content: "Coming Soon!";
  position: absolute;
  left: 0;
  bottom: -20px;
  background: #007D00;
  color: white;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.disabled-link:hover::after {
  opacity: .7;
  visibility: visible;
}
