/* Flex container for items */
.list_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 0 10px;
  margin: 0 auto;
  max-width: 1200px; /* Center the content and keep max width for larger screens */
}

/* Individual item styling */
.shelf-item {
  flex: 1 1 300px; /* Flex-grow and shrink with a base size */
  height: auto;
  position: relative;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 30px;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease, border 0.3s ease;
}

.shelf-item:hover {
  border: 1px solid #5b5a5e;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.shelf-item:hover > .shelf-item__buy-btn {
  background-color: #eabf00;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.1);
}

.shelf-item__thumb img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.shelf-item:hover .shelf-item__thumb img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Shelf stopper styling */
.shelf-stopper {
  position: absolute;
  top: 15px;
  right: 20px;
  background-color: #333;
  padding: 5px 12px;
  border-radius: 12px;
  text-align: center;
  color: white;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.shelf-item:hover > .shelf-stopper {
  background-color: #eabf00;
  color: black;
}

/* Item info */
.shelf-item .item_infor {
  padding: 10px 0;
  text-align: left;
}

.shelf-item .shelf-item__title {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

/* Buy button */
.shelf-item__buy-btn {
  display: block;
  margin: 20px auto 0;
  width: 80%;
  height: 45px;
  background-color: #67ddf5;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.shelf-item__buy-btn:hover {
  background-color: #52c0d9;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.15);
}

/* Responsive styling */
@media (max-width: 768px) {
  .shelf-item {
    flex: 1 1 100%; /* On small screens, make the items full-width */
    margin-bottom: 20px;
  }

  .shelf-item__buy-btn {
    width: 90%; /* Slightly wider button on small screens */
  }

  .list_items {
    padding: 0 5px;
  }
}

@media (max-width: 480px) {
  .shelf-item {
    padding: 10px;
  }

  .shelf-item__buy-btn {
    padding: 8px;
    font-size: 0.9rem;
  }
}
